import axios from 'axios';

// Устанавливаем базовый URL для всех запросов
axios.defaults.baseURL = 'https://adminhr.site/api/';

// Автоматическое включение Bearer токена в заголовок
// axios.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

export default axios;
