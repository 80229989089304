<script setup>

</script>

<template>
  <div id="root" class="_page_xudgm_1">
    <div class="_view_7yrcp_1">
      <div class="_title_7yrcp_13">Invite friends<br> and get more LOVE</div>
      
      <svg class="_width-126_oi8im_5 _centered_oi8im_1" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 20V5.99995M12 5.99995C10.2006 3.90293 7.19377 3.25485 4.93923 5.17509C2.68468 7.09533 2.36727 10.3059 4.13778 12.577C5.60984 14.4652 10.0648 18.4477 11.5249 19.7366C11.6882 19.8808 11.7699 19.9529 11.8652 19.9813C11.9483 20.006 12.0393 20.006 12.1225 19.9813C12.2178 19.9529 12.2994 19.8808 12.4628 19.7366C13.9229 18.4477 18.3778 14.4652 19.8499 12.577C21.6204 10.3059 21.3417 7.07513 19.0484 5.17509C16.7551 3.27505 13.7994 3.90293 12 5.99995Z"
              stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <div class="_subtitle_7yrcp_21">2 friends</div>

      <div class="_item_7yrcp_37">
        <div class="_media_7yrcp_44">
          <img class="_avatar_7yrcp_54"
               src="https://ui-avatars.com/api/?name=via_777&amp;background=random&amp;color=fff"
               loading="lazy" alt="Avatar"></div>
        <div class="_text_7yrcp_60">via_777</div>
        <div class="_details_7yrcp_69">+42 LOVE</div>
      </div>

      <div class="_item_7yrcp_37">
        <div class="_media_7yrcp_44">
          <img class="_avatar_7yrcp_54"
               src="https://ui-avatars.com/api/?name=via_777&amp;background=random&amp;color=fff"
               loading="lazy" alt="Avatar"></div>
        <div class="_text_7yrcp_60">via_777</div>
        <div class="_details_7yrcp_69">+42 LOVE</div>
      </div>

      <div class="_item_7yrcp_37">
        <div class="_media_7yrcp_44">
          <img class="_avatar_7yrcp_54"
               src="https://ui-avatars.com/api/?name=via_777&amp;background=random&amp;color=fff"
               loading="lazy" alt="Avatar"></div>
        <div class="_text_7yrcp_60">via_777</div>
        <div class="_details_7yrcp_69">+42 LOVE</div>
      </div>

      <div class="_item_7yrcp_37">
        <div class="_media_7yrcp_44">
          <img class="_avatar_7yrcp_54"
               src="https://ui-avatars.com/api/?name=via_777&amp;background=random&amp;color=fff"
               loading="lazy" alt="Avatar"></div>
        <div class="_text_7yrcp_60">via_777</div>
        <div class="_details_7yrcp_69">+42 LOVE</div>
      </div>

      <div class="_item_7yrcp_37">
        <div class="_media_7yrcp_44">
          <img class="_avatar_7yrcp_54"
               src="https://ui-avatars.com/api/?name=via_777&amp;background=random&amp;color=fff"
               loading="lazy" alt="Avatar"></div>
        <div class="_text_7yrcp_60">via_777</div>
        <div class="_details_7yrcp_69">+42 LOVE</div>
      </div>

      <div class="_buttonWrap_7yrcp_78">
        <div class="_root_a3nd8_1 _type-white_a3nd8_31">Invite friends</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
._root_a3nd8_1 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display),sans-serif;
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-size-adjust: 100%;
  padding: 0 16px;
  text-align: center;
  border-radius: 10px;
  font-size: 17px;
  min-height: 50px;
  height: 50px;
  font-weight: 590;
  border: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 2px;
  user-select: none;
  transition: background-color ease;
  transition-duration: .3s;
  cursor: pointer;
  background-color: #fff;
  color: #000;
}

._buttonWrap_7yrcp_78 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display),sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-size-adjust: 100%;
  background-color: #000;
  padding: 16px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 82px;
}

._details_7yrcp_69 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  margin-left: auto;
  padding-left: 14px;
  font-size: 16px;
  font-weight: 590;
  line-height: 21px;
  white-space: nowrap;
}

._text_7yrcp_60 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  font-size: 15px;
  font-weight: 590;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._avatar_7yrcp_54 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  max-width: 100%;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

._media_7yrcp_44 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-right: 14px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

._item_7yrcp_37 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  display: flex;
  height: 72px;
  min-height: 72px;
  -webkit-box-align: center;
  align-items: center;
}

._subtitle_7yrcp_21 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  font-size: 19px;
  font-weight: 590;
  line-height: 24px;
  margin: 18px 0 12px;
}

._width-126_oi8im_5 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  fill: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  margin: 0 auto;
  width: 126px;
  height: 130px;
  min-height: 130px;
}

._view_7yrcp_1 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  padding-top: 24px;
  margin-right: -12px;
  padding-right: 12px;
  padding-bottom: 122px;
  opacity: 1;
}

._title_7yrcp_13 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  font-size: 29px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 40px;
}

</style>