import axios from "axios";

const state = {
    userDataTg: [],
};

const mutations = {

    setUser(state, user) {
        state.userDataTg = user;
    },
};

const actions = {
    async fetchUserByTgId({commit},tgId) {
        
        try {
            const response = await axios.get(`telegram/award-verification/${tgId}`);

            commit('setUser', response.data);
        } catch (error) {
            console.error('Error fetching user:', error);
        } finally {
            console.error('finnaly');
        }
    },


};

const getters = {
    // getUserByTgId: (state) => () => {
    //     return state.userDataTg;
    // },

    getUserByTgId()
    {
        return state.userDataTg;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};