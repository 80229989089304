<template>
  <div id="root" class="_page_xudgm_1">
    <div class="_view_1cks3_1" style="opacity: 1">

      <div class="_view_1n0nb_1">

        <svg class="heart-img img-main" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 20V5.99995M12 5.99995C10.2006 3.90293 7.19377 3.25485 4.93923 5.17509C2.68468 7.09533 2.36727 10.3059 4.13778 12.577C5.60984 14.4652 10.0648 18.4477 11.5249 19.7366C11.6882 19.8808 11.7699 19.9529 11.8652 19.9813C11.9483 20.006 12.0393 20.006 12.1225 19.9813C12.2178 19.9529 12.2994 19.8808 12.4628 19.7366C13.9229 18.4477 18.3778 14.4652 19.8499 12.577C21.6204 10.3059 21.3417 7.07513 19.0484 5.17509C16.7551 3.27505 13.7994 3.90293 12 5.99995Z"
                stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="_title_1n0nb_12">
          {{this.user.balances_sum_sum}} LOVE
        </div>
        <div class="_title_1n0nb_12">
<!--          {{ test }}-->
        </div>

        <div class="_subscribe_1n0nb_72">
          <div class="_subscribeTitle_1n0nb_82">I LOVE YOU COMMUNITY</div>
          <div class="_subscribeText_1n0nb_87">Home for Telegram OGs</div>
          <div class="_subscribeButton_1n0nb_93">Join</div>
        </div>

        <div class="_rewardsTitle_1n0nb_118">Your rewards</div>
        <div class="_item_p7i4l_1">
          <div class="_media_p7i4l_8">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                 fill="none">
              <g clip-path="url(#clip0_368_746)">
                <path
                    d="M6.51673 9.70222C6.67082 9.37205 6.74787 9.20696 6.85394 9.15524C6.94614 9.11029 7.0539 9.11029 7.14611 9.15524C7.25217 9.20696 7.32922 9.37205 7.48331 9.70222L8.0317 10.8773C8.06207 10.9423 8.07726 10.9749 8.09833 11.0028C8.11702 11.0276 8.13908 11.0497 8.16387 11.0684C8.19183 11.0894 8.22436 11.1046 8.28944 11.135L9.46448 11.6834C9.79465 11.8375 9.95974 11.9145 10.0115 12.0206C10.0564 12.1128 10.0564 12.2206 10.0115 12.3128C9.95974 12.4188 9.79465 12.4959 9.46447 12.65L8.28944 13.1984C8.22436 13.2287 8.19183 13.2439 8.16387 13.265C8.13908 13.2837 8.11702 13.3057 8.09833 13.3305C8.07726 13.3585 8.06207 13.391 8.0317 13.4561L7.48331 14.6311C7.32922 14.9613 7.25217 15.1264 7.14611 15.1781C7.0539 15.2231 6.94614 15.2231 6.85394 15.1781C6.74787 15.1264 6.67082 14.9613 6.51673 14.6311L5.96834 13.4561C5.93797 13.391 5.92278 13.3585 5.90171 13.3305C5.88302 13.3057 5.86096 13.2837 5.83617 13.265C5.80822 13.2439 5.77568 13.2287 5.7106 13.1984L4.53557 12.65C4.20539 12.4959 4.04031 12.4188 3.98859 12.3128C3.94364 12.2206 3.94364 12.1128 3.98859 12.0206C4.04031 11.9145 4.20539 11.8375 4.53557 11.6834L5.7106 11.135C5.77568 11.1046 5.80822 11.0894 5.83617 11.0684C5.86096 11.0497 5.88302 11.0276 5.90171 11.0028C5.92278 10.9749 5.93797 10.9423 5.96834 10.8773L6.51673 9.70222Z"
                    fill="white"></path>
                <path
                    d="M11.6834 5.03555C11.8375 4.70538 11.9145 4.54029 12.0206 4.48858C12.1128 4.44362 12.2206 4.44362 12.3128 4.48858C12.4188 4.54029 12.4959 4.70538 12.65 5.03555L13.3575 6.55149C13.3878 6.61656 13.403 6.6491 13.4241 6.67705C13.4428 6.70185 13.4648 6.72391 13.4896 6.7426C13.5176 6.76367 13.5501 6.77885 13.6152 6.80923L15.1311 7.51671C15.4613 7.67081 15.6264 7.74785 15.6781 7.85392C15.7231 7.94612 15.7231 8.05389 15.6781 8.14609C15.6264 8.25216 15.4613 8.3292 15.1311 8.4833L13.6152 9.19079C13.5501 9.22116 13.5176 9.23634 13.4896 9.25741C13.4648 9.2761 13.4428 9.29816 13.4241 9.32296C13.403 9.35091 13.3878 9.38345 13.3575 9.44852L12.65 10.9645C12.4959 11.2946 12.4188 11.4597 12.3128 11.5114C12.2206 11.5564 12.1128 11.5564 12.0206 11.5114C11.9145 11.4597 11.8375 11.2946 11.6834 10.9645L10.9759 9.44852C10.9455 9.38345 10.9304 9.35091 10.9093 9.32296C10.8906 9.29816 10.8685 9.2761 10.8437 9.25741C10.8158 9.23634 10.7832 9.22116 10.7182 9.19079L9.20224 8.4833C8.87206 8.3292 8.70697 8.25216 8.65526 8.14609C8.6103 8.05389 8.6103 7.94612 8.65526 7.85392C8.70697 7.74785 8.87206 7.67081 9.20223 7.51671L10.7182 6.80923C10.7832 6.77885 10.8158 6.76367 10.8437 6.7426C10.8685 6.72391 10.8906 6.70185 10.9093 6.67705C10.9304 6.6491 10.9455 6.61656 10.9759 6.55149L11.6834 5.03555Z"
                    fill="white"></path>
                <path
                    d="M4.35006 1.36889C4.50416 1.03871 4.5812 0.873625 4.68727 0.821909C4.77947 0.776954 4.88724 0.776954 4.97944 0.821909C5.08551 0.873625 5.16255 1.03871 5.31665 1.36889L6.18323 3.22572C6.2136 3.2908 6.22879 3.32334 6.24986 3.35129C6.26855 3.37608 6.29061 3.39814 6.31541 3.41683C6.34336 3.4379 6.3759 3.45309 6.44097 3.48346L8.29781 4.35005C8.62798 4.50414 8.79307 4.58119 8.84478 4.68725C8.88974 4.77946 8.88974 4.88722 8.84478 4.97942C8.79307 5.08549 8.62798 5.16254 8.29781 5.31663L6.44097 6.18322C6.3759 6.21359 6.34336 6.22878 6.31541 6.24984C6.29061 6.26853 6.26855 6.2906 6.24986 6.31539C6.22879 6.34334 6.2136 6.37588 6.18323 6.44096L5.31665 8.29779C5.16255 8.62797 5.08551 8.79305 4.97944 8.84477C4.88724 8.88972 4.77947 8.88972 4.68727 8.84477C4.5812 8.79305 4.50416 8.62797 4.35006 8.29779L3.48348 6.44096C3.4531 6.37588 3.43792 6.34334 3.41685 6.31539C3.39816 6.2906 3.3761 6.26853 3.3513 6.24984C3.32335 6.22878 3.29081 6.21359 3.22574 6.18322L1.3689 5.31663C1.03873 5.16254 0.87364 5.08549 0.821924 4.97942C0.776969 4.88722 0.776969 4.77946 0.821924 4.68725C0.87364 4.58119 1.03873 4.50414 1.3689 4.35005L3.22574 3.48346C3.29081 3.45309 3.32335 3.4379 3.3513 3.41683C3.3761 3.39814 3.39816 3.37608 3.41685 3.35129C3.43792 3.32334 3.4531 3.2908 3.48348 3.22572L4.35006 1.36889Z"
                    fill="white"></path>
              </g>
              <defs>
                <clipPath id="clip0_368_746">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="_body_p7i4l_28">
            <div class="_text_p7i4l_42">Account age</div>
          </div>
          <div class="_details_p7i4l_51">+3,573 LOVE</div>
        </div>

        <div class="_item_p7i4l_1">
          <div class="_media_p7i4l_8">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                 fill="none">
              <g clip-path="url(#clip0_368_746)">
                <path
                    d="M6.51673 9.70222C6.67082 9.37205 6.74787 9.20696 6.85394 9.15524C6.94614 9.11029 7.0539 9.11029 7.14611 9.15524C7.25217 9.20696 7.32922 9.37205 7.48331 9.70222L8.0317 10.8773C8.06207 10.9423 8.07726 10.9749 8.09833 11.0028C8.11702 11.0276 8.13908 11.0497 8.16387 11.0684C8.19183 11.0894 8.22436 11.1046 8.28944 11.135L9.46448 11.6834C9.79465 11.8375 9.95974 11.9145 10.0115 12.0206C10.0564 12.1128 10.0564 12.2206 10.0115 12.3128C9.95974 12.4188 9.79465 12.4959 9.46447 12.65L8.28944 13.1984C8.22436 13.2287 8.19183 13.2439 8.16387 13.265C8.13908 13.2837 8.11702 13.3057 8.09833 13.3305C8.07726 13.3585 8.06207 13.391 8.0317 13.4561L7.48331 14.6311C7.32922 14.9613 7.25217 15.1264 7.14611 15.1781C7.0539 15.2231 6.94614 15.2231 6.85394 15.1781C6.74787 15.1264 6.67082 14.9613 6.51673 14.6311L5.96834 13.4561C5.93797 13.391 5.92278 13.3585 5.90171 13.3305C5.88302 13.3057 5.86096 13.2837 5.83617 13.265C5.80822 13.2439 5.77568 13.2287 5.7106 13.1984L4.53557 12.65C4.20539 12.4959 4.04031 12.4188 3.98859 12.3128C3.94364 12.2206 3.94364 12.1128 3.98859 12.0206C4.04031 11.9145 4.20539 11.8375 4.53557 11.6834L5.7106 11.135C5.77568 11.1046 5.80822 11.0894 5.83617 11.0684C5.86096 11.0497 5.88302 11.0276 5.90171 11.0028C5.92278 10.9749 5.93797 10.9423 5.96834 10.8773L6.51673 9.70222Z"
                    fill="white"></path>
                <path
                    d="M11.6834 5.03555C11.8375 4.70538 11.9145 4.54029 12.0206 4.48858C12.1128 4.44362 12.2206 4.44362 12.3128 4.48858C12.4188 4.54029 12.4959 4.70538 12.65 5.03555L13.3575 6.55149C13.3878 6.61656 13.403 6.6491 13.4241 6.67705C13.4428 6.70185 13.4648 6.72391 13.4896 6.7426C13.5176 6.76367 13.5501 6.77885 13.6152 6.80923L15.1311 7.51671C15.4613 7.67081 15.6264 7.74785 15.6781 7.85392C15.7231 7.94612 15.7231 8.05389 15.6781 8.14609C15.6264 8.25216 15.4613 8.3292 15.1311 8.4833L13.6152 9.19079C13.5501 9.22116 13.5176 9.23634 13.4896 9.25741C13.4648 9.2761 13.4428 9.29816 13.4241 9.32296C13.403 9.35091 13.3878 9.38345 13.3575 9.44852L12.65 10.9645C12.4959 11.2946 12.4188 11.4597 12.3128 11.5114C12.2206 11.5564 12.1128 11.5564 12.0206 11.5114C11.9145 11.4597 11.8375 11.2946 11.6834 10.9645L10.9759 9.44852C10.9455 9.38345 10.9304 9.35091 10.9093 9.32296C10.8906 9.29816 10.8685 9.2761 10.8437 9.25741C10.8158 9.23634 10.7832 9.22116 10.7182 9.19079L9.20224 8.4833C8.87206 8.3292 8.70697 8.25216 8.65526 8.14609C8.6103 8.05389 8.6103 7.94612 8.65526 7.85392C8.70697 7.74785 8.87206 7.67081 9.20223 7.51671L10.7182 6.80923C10.7832 6.77885 10.8158 6.76367 10.8437 6.7426C10.8685 6.72391 10.8906 6.70185 10.9093 6.67705C10.9304 6.6491 10.9455 6.61656 10.9759 6.55149L11.6834 5.03555Z"
                    fill="white"></path>
                <path
                    d="M4.35006 1.36889C4.50416 1.03871 4.5812 0.873625 4.68727 0.821909C4.77947 0.776954 4.88724 0.776954 4.97944 0.821909C5.08551 0.873625 5.16255 1.03871 5.31665 1.36889L6.18323 3.22572C6.2136 3.2908 6.22879 3.32334 6.24986 3.35129C6.26855 3.37608 6.29061 3.39814 6.31541 3.41683C6.34336 3.4379 6.3759 3.45309 6.44097 3.48346L8.29781 4.35005C8.62798 4.50414 8.79307 4.58119 8.84478 4.68725C8.88974 4.77946 8.88974 4.88722 8.84478 4.97942C8.79307 5.08549 8.62798 5.16254 8.29781 5.31663L6.44097 6.18322C6.3759 6.21359 6.34336 6.22878 6.31541 6.24984C6.29061 6.26853 6.26855 6.2906 6.24986 6.31539C6.22879 6.34334 6.2136 6.37588 6.18323 6.44096L5.31665 8.29779C5.16255 8.62797 5.08551 8.79305 4.97944 8.84477C4.88724 8.88972 4.77947 8.88972 4.68727 8.84477C4.5812 8.79305 4.50416 8.62797 4.35006 8.29779L3.48348 6.44096C3.4531 6.37588 3.43792 6.34334 3.41685 6.31539C3.39816 6.2906 3.3761 6.26853 3.3513 6.24984C3.32335 6.22878 3.29081 6.21359 3.22574 6.18322L1.3689 5.31663C1.03873 5.16254 0.87364 5.08549 0.821924 4.97942C0.776969 4.88722 0.776969 4.77946 0.821924 4.68725C0.87364 4.58119 1.03873 4.50414 1.3689 4.35005L3.22574 3.48346C3.29081 3.45309 3.32335 3.4379 3.3513 3.41683C3.3761 3.39814 3.39816 3.37608 3.41685 3.35129C3.43792 3.32334 3.4531 3.2908 3.48348 3.22572L4.35006 1.36889Z"
                    fill="white"></path>
              </g>
              <defs>
                <clipPath id="clip0_368_746">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="_body_p7i4l_28">
            <div class="_text_p7i4l_42">Account age</div>
          </div>
          <div class="_details_p7i4l_51">+3,573 LOVE</div>
        </div>

        <div class="_item_p7i4l_1">
          <div class="_media_p7i4l_8">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                 fill="none">
              <g clip-path="url(#clip0_368_746)">
                <path
                    d="M6.51673 9.70222C6.67082 9.37205 6.74787 9.20696 6.85394 9.15524C6.94614 9.11029 7.0539 9.11029 7.14611 9.15524C7.25217 9.20696 7.32922 9.37205 7.48331 9.70222L8.0317 10.8773C8.06207 10.9423 8.07726 10.9749 8.09833 11.0028C8.11702 11.0276 8.13908 11.0497 8.16387 11.0684C8.19183 11.0894 8.22436 11.1046 8.28944 11.135L9.46448 11.6834C9.79465 11.8375 9.95974 11.9145 10.0115 12.0206C10.0564 12.1128 10.0564 12.2206 10.0115 12.3128C9.95974 12.4188 9.79465 12.4959 9.46447 12.65L8.28944 13.1984C8.22436 13.2287 8.19183 13.2439 8.16387 13.265C8.13908 13.2837 8.11702 13.3057 8.09833 13.3305C8.07726 13.3585 8.06207 13.391 8.0317 13.4561L7.48331 14.6311C7.32922 14.9613 7.25217 15.1264 7.14611 15.1781C7.0539 15.2231 6.94614 15.2231 6.85394 15.1781C6.74787 15.1264 6.67082 14.9613 6.51673 14.6311L5.96834 13.4561C5.93797 13.391 5.92278 13.3585 5.90171 13.3305C5.88302 13.3057 5.86096 13.2837 5.83617 13.265C5.80822 13.2439 5.77568 13.2287 5.7106 13.1984L4.53557 12.65C4.20539 12.4959 4.04031 12.4188 3.98859 12.3128C3.94364 12.2206 3.94364 12.1128 3.98859 12.0206C4.04031 11.9145 4.20539 11.8375 4.53557 11.6834L5.7106 11.135C5.77568 11.1046 5.80822 11.0894 5.83617 11.0684C5.86096 11.0497 5.88302 11.0276 5.90171 11.0028C5.92278 10.9749 5.93797 10.9423 5.96834 10.8773L6.51673 9.70222Z"
                    fill="white"></path>
                <path
                    d="M11.6834 5.03555C11.8375 4.70538 11.9145 4.54029 12.0206 4.48858C12.1128 4.44362 12.2206 4.44362 12.3128 4.48858C12.4188 4.54029 12.4959 4.70538 12.65 5.03555L13.3575 6.55149C13.3878 6.61656 13.403 6.6491 13.4241 6.67705C13.4428 6.70185 13.4648 6.72391 13.4896 6.7426C13.5176 6.76367 13.5501 6.77885 13.6152 6.80923L15.1311 7.51671C15.4613 7.67081 15.6264 7.74785 15.6781 7.85392C15.7231 7.94612 15.7231 8.05389 15.6781 8.14609C15.6264 8.25216 15.4613 8.3292 15.1311 8.4833L13.6152 9.19079C13.5501 9.22116 13.5176 9.23634 13.4896 9.25741C13.4648 9.2761 13.4428 9.29816 13.4241 9.32296C13.403 9.35091 13.3878 9.38345 13.3575 9.44852L12.65 10.9645C12.4959 11.2946 12.4188 11.4597 12.3128 11.5114C12.2206 11.5564 12.1128 11.5564 12.0206 11.5114C11.9145 11.4597 11.8375 11.2946 11.6834 10.9645L10.9759 9.44852C10.9455 9.38345 10.9304 9.35091 10.9093 9.32296C10.8906 9.29816 10.8685 9.2761 10.8437 9.25741C10.8158 9.23634 10.7832 9.22116 10.7182 9.19079L9.20224 8.4833C8.87206 8.3292 8.70697 8.25216 8.65526 8.14609C8.6103 8.05389 8.6103 7.94612 8.65526 7.85392C8.70697 7.74785 8.87206 7.67081 9.20223 7.51671L10.7182 6.80923C10.7832 6.77885 10.8158 6.76367 10.8437 6.7426C10.8685 6.72391 10.8906 6.70185 10.9093 6.67705C10.9304 6.6491 10.9455 6.61656 10.9759 6.55149L11.6834 5.03555Z"
                    fill="white"></path>
                <path
                    d="M4.35006 1.36889C4.50416 1.03871 4.5812 0.873625 4.68727 0.821909C4.77947 0.776954 4.88724 0.776954 4.97944 0.821909C5.08551 0.873625 5.16255 1.03871 5.31665 1.36889L6.18323 3.22572C6.2136 3.2908 6.22879 3.32334 6.24986 3.35129C6.26855 3.37608 6.29061 3.39814 6.31541 3.41683C6.34336 3.4379 6.3759 3.45309 6.44097 3.48346L8.29781 4.35005C8.62798 4.50414 8.79307 4.58119 8.84478 4.68725C8.88974 4.77946 8.88974 4.88722 8.84478 4.97942C8.79307 5.08549 8.62798 5.16254 8.29781 5.31663L6.44097 6.18322C6.3759 6.21359 6.34336 6.22878 6.31541 6.24984C6.29061 6.26853 6.26855 6.2906 6.24986 6.31539C6.22879 6.34334 6.2136 6.37588 6.18323 6.44096L5.31665 8.29779C5.16255 8.62797 5.08551 8.79305 4.97944 8.84477C4.88724 8.88972 4.77947 8.88972 4.68727 8.84477C4.5812 8.79305 4.50416 8.62797 4.35006 8.29779L3.48348 6.44096C3.4531 6.37588 3.43792 6.34334 3.41685 6.31539C3.39816 6.2906 3.3761 6.26853 3.3513 6.24984C3.32335 6.22878 3.29081 6.21359 3.22574 6.18322L1.3689 5.31663C1.03873 5.16254 0.87364 5.08549 0.821924 4.97942C0.776969 4.88722 0.776969 4.77946 0.821924 4.68725C0.87364 4.58119 1.03873 4.50414 1.3689 4.35005L3.22574 3.48346C3.29081 3.45309 3.32335 3.4379 3.3513 3.41683C3.3761 3.39814 3.39816 3.37608 3.41685 3.35129C3.43792 3.32334 3.4531 3.2908 3.48348 3.22572L4.35006 1.36889Z"
                    fill="white"></path>
              </g>
              <defs>
                <clipPath id="clip0_368_746">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="_body_p7i4l_28">
            <div class="_text_p7i4l_42">Account age</div>
          </div>
          <div class="_details_p7i4l_51">+3,573 LOVE</div>
        </div>
        <div class="_item_p7i4l_1">
          <div class="_media_p7i4l_8">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 fill="none">
              <path d="M6 12L10.2426 16.2426L18.727 7.75732" stroke="white" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div class="_body_p7i4l_28">
            <div class="_text_p7i4l_42">Telegram Premium</div>
          </div>
          <div class="_details_p7i4l_51">+300 LOVE</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapState } from 'vuex';

import axios from "axios";

export default {
  name: 'HomeView',

  computed: {
    ...mapGetters('user', ['getUserByTgId']),
    ...mapState('user', ['userDataTg']),
    user() {
      return this.userDataTg;
    },
  },

  // created() {
  //   console.log('getUserFromState',this.user); // доступ к текущему пользователю
  // },

  data() {
    return {
      test: 'TEST',
      userHome: null
    };
  },

  components: {

  },

  methods: {
    ...mapActions('user', ['fetchUserByTgId'])
  },

  async mounted() {
    console.log('Компонент был смонтирован!', window.Telegram.WebApp);
    // console.log('Компонент был смонтирован!');
    // console.log('getUserFromState',this.getUserByTgId);
    // this.userHome = this.getUserByTgId;

    // if (this.userHome === null) {
      this.fetchUserByTgId(window.Telegram.WebApp.initDataUnsafe.user.id)
    // }
    try {
      const response = await axios.get('https://adminhr.site/api/telegram/user-exist', {
        params: {
          userData: window.Telegram.WebApp.initDataUnsafe
        }
      });
      this.test = response.data
      console.log(response.data);
    } catch (error) {
      this.error = 'Произошла ошибка при загрузке данных.';
    }
  }
}
</script>

<style>
._view_1n0nb_1 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display),sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-size-adjust: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  padding-top: 25px;
  margin-right: -12px;
  padding-right: 12px;
  opacity: 1;
}
</style>
