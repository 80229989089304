<template>
  <nav>
    <!--    <router-link to="/">Home</router-link> |-->
    <!--    <router-link to="/about">About</router-link>-->
  </nav>
  <div v-if="userExists" class="main-content">
    <div class="_body_1cks3_8">
      <router-view/>

    </div>

    <div class="_navbar_1cks3_13">
      <div class="_navbarItem_1cks3_23">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
              d="M4 11.4522V16.8002C4 17.9203 4 18.4807 4.21799 18.9086C4.40973 19.2849 4.71547 19.5906 5.0918 19.7823C5.5192 20.0001 6.07899 20.0001 7.19691 20.0001H16.8031C17.921 20.0001 18.48 20.0001 18.9074 19.7823C19.2837 19.5906 19.5905 19.2849 19.7822 18.9086C20 18.4811 20 17.9216 20 16.8037V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522Z"
              stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <div class="_navbarItemTitle_1cks3_32">
          <router-link :to="{ name: 'home' }" class="unstyled-link">
            Home
          </router-link>
        </div>
      </div>
      <div class="_navbarItem_1cks3_23">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
              d="M9 11V20M9 11H4.59961C4.03956 11 3.75981 11 3.5459 11.109C3.35774 11.2049 3.20487 11.3577 3.10899 11.5459C3 11.7598 3 12.04 3 12.6001V20H9M9 11V5.6001C9 5.04004 9 4.75981 9.10899 4.5459C9.20487 4.35774 9.35774 4.20487 9.5459 4.10899C9.75981 4 10.0396 4 10.5996 4H13.3996C13.9597 4 14.2403 4 14.4542 4.10899C14.6423 4.20487 14.7948 4.35774 14.8906 4.5459C14.9996 4.75981 15 5.04005 15 5.6001V8M9 20H15M15 20L21 20.0001V9.6001C21 9.04005 20.9996 8.75981 20.8906 8.5459C20.7948 8.35774 20.6429 8.20487 20.4548 8.10899C20.2409 8 19.9601 8 19.4 8H15M15 20V8"
              stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <div class="_navbarItemTitle_1cks3_32">Leaderboard</div>
      </div>
      <div class="_navbarItem_1cks3_23">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
              d="M21 19.9999C21 18.2583 19.3304 16.7767 17 16.2275M15 20C15 17.7909 12.3137 16 9 16C5.68629 16 3 17.7909 3 20M15 13C17.2091 13 19 11.2091 19 9C19 6.79086 17.2091 5 15 5M9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9C13 11.2091 11.2091 13 9 13Z"
              stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <div class="_navbarItemTitle_1cks3_32">
          <router-link class="unstyled-link" :to="{ name: 'frends' }">
            Frends
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div v-if="userExists === false">
    <div id="root" class="_page_xudgm_1">
      <div v-if='showContent === "welcome_window"' class="welcome_window">
        <div class="_image_15p71_9">
          <div class="background">
            <div class="heart"></div>
            <div class="heart"></div>
            <div class="heart"></div>
            <div class="heart"></div>
            <div class="heart"></div>
          </div>
        </div>
        <div class="_text_15p71_16">👋 Hello dear friend!<br>You've been using Telegram for some time now,<br>so we're
          giving you LOVE tokens!
        </div>

        <div class="_root_a3nd8_1 _type-blue_a3nd8_39 _fixedBottom_a3nd8_58" @click='showAwardCalculation()'>
          Wow, get LOVE !
        </div>
      </div>

      <div v-if='showContent === "award_calculation"' class="award_calculation">
        <div class="_view_jzemx_1">
          <div class="_title_jzemx_9">Checking your account</div>

          <!--          <div  class="_loaders_jzemx_15">-->

          <transition-group name="loader" tag="div" class="_loaders_jzemx_15">
            <div v-for="(item, index) in loaders" :key="index" v-show="item.visible" class="loader-container">
              <div class="item">
                <div class="_top_jzemx_39">
                  <div class="_name_jzemx_33">
                    {{ item.text }}
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       class="_checkmark_jzemx_23 _isCompleted_jzemx_26">
                    <path
                        d="M15 10L11 14L9 12M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                        stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="_loader_jzemx_15">
                  <div class="_loaderProgress_jzemx_51" :style="{ width: item.progress + '%' }"></div>
                </div>
              </div>

            </div>
          </transition-group>

          <!--          </div>-->

          <transition name="fade">
            <div @click="showContent = 'reward_slide'" class="_root_a3nd8_1 _type-blue_a3nd8_39 _fixedBottom_a3nd8_58"
                 v-if="isLastProgress">Continue
            </div>
          </transition>
        </div>

      </div>

      <!-- Слайд объявление наград  -->
      <div v-if='showContent === "reward_slide"'>
        <div id="root" class="_page_xudgm_1">

          <div class="_view_kdytn_11">


            <div class="_animation_kdytn_73">

              <div title="" role="button" aria-label="animation" tabindex="0"
                   style="width: 100%; height: 100%; overflow: hidden;  outline: none; display: flex; justify-content: end">

                <svg style="background-color: white" version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="200px" height="200px" viewBox="0 0 1024.000000 1024.000000"
                     preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                     fill="#000000" stroke="none">
                    <path d="M0 5120 l0 -5120 2031 0 2031 0 -6 33 c-11 63 -26 213 -26 272 l0 60
-71 40 c-82 46 -158 117 -184 171 -31 66 -30 120 4 253 17 66 31 141 31 166 0
26 5 56 12 68 11 21 11 21 19 2 4 -11 8 -32 8 -46 1 -39 56 -138 104 -186 33
-35 39 -45 28 -55 -10 -11 -21 -3 -56 37 -24 28 -52 67 -62 89 l-17 39 -18
-69 c-49 -188 -52 -209 -38 -261 28 -100 157 -192 405 -291 245 -97 432 -144
595 -148 95 -2 116 -6 118 -18 4 -19 7 -19 -116 -6 -201 22 -431 79 -623 156
-48 19 -89 34 -93 34 -8 0 -8 -268 0 -309 l6 -31 94 0 93 0 4 38 4 37 1 -37 2
-38 588 0 589 0 26 49 c15 27 27 50 27 52 0 2 -64 4 -143 5 -162 0 -200 6
-195 32 3 11 10 15 23 12 11 -2 86 -5 166 -5 107 0 153 4 172 14 15 8 29 12
32 9 3 -3 -11 -40 -30 -82 -19 -42 -35 -79 -35 -82 0 -2 142 -4 315 -4 l315 0
2 48 c3 103 18 211 29 218 5 2 5 -25 2 -62 l-6 -68 32 23 c17 13 44 46 59 74
26 51 27 56 24 197 -3 156 4 214 50 414 16 71 34 181 39 245 5 64 21 188 36
276 14 88 26 197 27 241 1 76 -1 86 -39 159 -56 109 -69 110 -62 4 3 -46 11
-109 17 -139 8 -34 10 -93 7 -155 -6 -103 -52 -357 -87 -480 -31 -109 -102
-295 -113 -295 -5 0 -6 8 -1 17 19 42 87 255 105 328 49 203 85 537 62 579 -8
14 -12 6 -23 -43 -7 -34 -17 -64 -22 -67 -5 -3 -6 5 -3 18 14 59 25 164 30
278 3 69 10 170 15 225 31 304 29 293 49 280 16 -10 12 -134 -8 -273 l-19
-124 34 -51 c19 -29 47 -77 62 -107 40 -82 37 -197 -12 -439 -20 -101 -34
-191 -31 -201 8 -25 -10 -137 -56 -344 -39 -172 -40 -183 -37 -335 3 -153 2
-157 -25 -212 -18 -37 -41 -66 -67 -84 -34 -24 -40 -33 -40 -64 0 -20 -3 -46
-6 -58 l-6 -23 2046 0 2046 0 0 5120 0 5120 -5120 0 -5120 0 0 -5120z m8102
4563 c62 -59 58 -148 -14 -295 -22 -45 -50 -110 -62 -143 -19 -51 -26 -60 -49
-63 -14 -2 -39 5 -54 16 -15 11 -51 34 -79 51 -66 40 -149 128 -174 183 -64
140 39 251 178 193 25 -11 38 -12 40 -5 2 6 16 25 30 42 53 63 131 72 184 21z
m-700 -383 c23 -45 24 -133 2 -154 -34 -35 -133 -7 -160 46 -24 46 -15 73 24
73 26 0 32 4 32 22 0 61 74 70 102 13z m182 -364 c78 -33 123 -150 112 -296
-7 -98 -30 -189 -80 -310 -39 -95 -60 -110 -99 -68 -12 13 -66 48 -120 78
-120 66 -204 146 -247 234 -52 105 -50 229 3 258 49 26 128 -3 197 -72 31 -31
40 -36 40 -22 0 44 34 126 66 162 31 34 44 41 87 49 4 0 23 -5 41 -13z m775
-290 c41 -22 64 -82 59 -154 -3 -48 -15 -82 -60 -168 -68 -134 -81 -144 -133
-107 -79 56 -151 137 -168 188 -32 92 -15 194 37 230 33 24 90 19 124 -10 l30
-25 30 30 c35 34 42 36 81 16z m-2181 -627 c4 -21 -106 -90 -260 -165 -62 -30
-102 -52 -88 -48 46 12 401 17 412 6 7 -7 48 -9 112 -5 215 11 382 -71 492
-243 38 -59 74 -144 74 -174 0 -26 -22 -25 -46 1 -19 22 -158 119 -169 119 -3
0 14 -28 39 -63 98 -138 148 -280 162 -461 8 -103 0 -156 -25 -156 -12 0 -22
15 -34 54 -10 29 -41 102 -70 160 l-52 107 -2 -68 c-1 -37 -8 -86 -16 -108
-13 -37 -13 -43 6 -80 16 -30 22 -62 24 -129 1 -60 6 -92 15 -98 10 -6 9 -8
-4 -8 -15 0 -15 -4 -2 -47 33 -111 37 -114 164 -132 61 -8 79 -15 98 -36 28
-33 28 -49 -3 -160 l-25 -89 25 -48 c14 -27 25 -64 25 -87 0 -21 7 -55 15 -76
15 -34 15 -40 -7 -98 -20 -55 -22 -78 -20 -199 l2 -137 -32 -36 c-32 -37 -159
-108 -213 -119 -16 -3 -102 -13 -190 -21 -88 -8 -175 -19 -193 -25 -31 -8 -83
-49 -206 -162 l-47 -43 68 -70 c124 -128 190 -251 200 -373 4 -57 1 -79 -22
-142 -15 -41 -29 -93 -31 -115 -3 -22 -11 -53 -19 -70 -8 -16 -17 -42 -20 -57
-10 -50 -25 -29 -25 35 0 83 -19 145 -64 206 -20 27 -36 54 -36 60 0 21 23 11
57 -26 l33 -38 -6 30 c-16 76 -108 269 -152 318 -8 9 -12 20 -8 24 17 17 103
-132 167 -287 49 -122 54 -130 62 -104 17 56 10 185 -13 254 -26 79 -47 114
-117 203 -159 199 -518 420 -776 477 -76 17 -87 35 -16 25 186 -25 428 -135
628 -284 72 -54 74 -54 90 -34 135 167 152 187 182 206 25 17 163 38 368 57
135 12 176 27 230 80 17 18 33 41 35 53 9 43 21 328 16 405 0 9 2 33 6 53 4
28 0 48 -21 87 l-27 51 18 105 18 105 -23 25 c-17 18 -40 26 -94 34 -39 6 -82
19 -96 30 -30 22 -58 90 -64 153 -2 25 -10 45 -18 47 -8 2 -19 11 -26 19 -15
20 -29 10 -54 -39 -24 -46 -33 -46 -19 1 21 73 55 98 90 66 15 -13 16 -9 9 56
-11 96 -36 121 -85 84 -39 -30 -52 -20 -59 48 -14 123 -47 257 -94 376 -30 73
-19 76 17 5 43 -85 78 -196 96 -298 16 -91 22 -109 30 -85 2 8 9 29 15 47 6
19 14 63 17 98 11 102 27 78 22 -31 -3 -52 -2 -95 2 -95 15 0 41 95 47 166 7
89 -8 159 -54 243 -18 35 -32 66 -29 68 9 9 90 -106 168 -238 43 -73 78 -129
78 -124 0 6 -9 46 -20 90 -11 44 -18 91 -15 105 17 79 -156 329 -267 386 -15
8 -28 18 -28 23 0 12 135 -34 223 -77 42 -21 77 -35 77 -32 0 4 -24 31 -54 61
-99 103 -190 148 -334 169 -115 17 -565 8 -692 -14 -134 -23 -267 -60 -345
-95 -43 -20 -63 -25 -54 -15 29 35 187 98 341 137 31 8 62 20 70 26 7 6 58 33
113 60 119 58 250 135 215 127 -135 -32 -412 -114 -522 -154 -182 -66 -252
-100 -321 -158 -75 -64 -247 -264 -247 -288 0 -38 -30 6 -52 78 -18 55 -22 96
-23 186 0 63 3 129 7 145 6 23 3 20 -11 -11 -34 -74 -46 -153 -45 -304 l0
-145 -34 33 c-64 61 -271 192 -305 192 -9 0 -18 4 -22 9 -31 51 206 -65 291
-142 l61 -56 -14 87 c-27 179 20 372 124 500 32 39 73 57 73 30 0 -6 -9 -31
-21 -54 -45 -95 -75 -352 -53 -464 l7 -32 12 28 c19 44 136 176 219 247 119
101 364 210 469 209 42 -1 118 16 270 58 117 32 219 58 227 57 8 -1 16 -9 18
-18z m1282 -178 c14 -28 12 -77 -5 -110 -16 -33 -92 -101 -111 -101 -7 0 -29
22 -48 49 -37 50 -47 92 -32 138 10 31 51 40 77 17 19 -17 20 -17 40 4 25 27
65 29 79 3z m-1189 -201 c112 -45 242 -155 300 -256 48 -85 28 -86 -30 -1
-109 157 -322 282 -449 264 l-37 -5 30 14 c38 18 116 11 186 -16z m1623 8 c24
-34 20 -123 -8 -184 -37 -79 -61 -80 -133 -1 -49 53 -68 103 -54 141 14 34 61
36 84 4 15 -22 16 -22 16 -3 1 32 30 65 56 65 13 0 31 -10 39 -22z m507 -47
c21 -22 29 -39 29 -65 0 -20 2 -36 5 -36 2 0 19 9 37 20 75 46 144 15 142 -64
-2 -92 -71 -175 -209 -249 -86 -45 -126 -56 -152 -39 -22 14 -56 161 -50 217
10 93 36 157 82 202 50 51 76 54 116 14z m-3647 -217 c9 -8 16 -16 16 -17 0
-1 -42 -2 -93 -2 -87 0 -98 -2 -170 -38 -69 -34 -187 -130 -175 -143 3 -2 25
3 49 12 81 30 308 30 310 0 0 -5 -27 -20 -61 -32 -175 -66 -300 -217 -365
-440 -32 -111 -32 -126 4 -77 16 22 53 63 82 89 77 72 104 102 157 174 50 68
122 130 150 129 13 0 11 -4 -8 -16 -53 -35 -137 -133 -168 -196 -57 -116 -75
-211 -69 -362 6 -145 19 -201 80 -358 l42 -107 6 102 c7 111 36 245 38 178 4
-112 64 -251 157 -364 56 -67 180 -173 237 -202 18 -9 56 -34 85 -56 29 -21
71 -47 95 -57 23 -10 56 -34 72 -53 17 -19 37 -37 45 -40 7 -2 11 -9 7 -15 -4
-7 -29 -6 -79 4 -79 16 -207 11 -219 -8 -4 -8 -16 -6 -35 4 l-29 16 40 17 c32
13 60 15 143 11 56 -3 102 -2 102 2 0 17 -27 31 -62 31 -139 0 -379 219 -510
465 -36 67 -37 69 -37 35 0 -37 12 -148 24 -216 6 -34 4 -42 -11 -48 -26 -10
-41 15 -49 84 -5 42 -10 57 -20 54 -35 -14 -91 90 -123 231 -48 204 -50 387
-7 532 l14 48 -34 -40 c-19 -22 -60 -88 -90 -147 -47 -90 -60 -108 -80 -108
-32 0 -36 19 -25 120 21 187 96 371 192 469 64 66 162 131 196 131 14 0 22 3
18 7 -11 12 -165 3 -235 -13 -35 -8 -66 -12 -68 -10 -8 7 28 59 76 110 87 92
162 125 288 126 61 0 85 -4 97 -16z m1654 -181 c22 -127 24 -189 13 -318 -15
-168 -30 -251 -46 -265 -16 -13 -13 -20 -51 144 l-27 120 -43 -155 c-36 -131
-70 -235 -134 -416 -12 -33 -40 -46 -40 -18 0 9 -23 61 -51 116 -59 118 -50
122 19 9 l49 -79 11 32 c5 18 31 93 57 167 53 151 125 396 125 422 0 9 4 19 8
22 5 3 9 -16 9 -42 -1 -26 12 -96 28 -157 27 -102 30 -107 36 -75 26 125 37
392 20 508 -11 76 4 64 17 -15z m968 29 c49 -32 36 -146 -24 -214 -34 -40
-108 0 -143 77 -23 49 -24 79 -3 99 20 20 63 21 70 1 5 -11 12 -7 31 15 24 30
47 37 69 22z m617 -284 c30 -40 25 -93 -17 -185 -20 -44 -36 -88 -36 -97 0 -9
-11 -22 -25 -28 -22 -10 -29 -9 -48 10 -12 13 -41 36 -63 52 -82 56 -118 186
-69 240 22 24 68 34 93 21 14 -8 28 -6 56 8 45 23 81 16 109 -21z m-1367 -363
c-1 -78 -9 -92 -27 -51 -9 19 -8 35 7 75 10 28 19 51 20 51 1 0 1 -34 0 -75z
m955 -19 c9 -20 7 -31 -11 -65 -38 -66 -62 -62 -95 14 -17 39 -4 68 29 59 13
-3 27 -1 30 5 12 19 36 12 47 -13z m790 8 c14 -13 21 -13 40 -4 19 11 25 10
36 -6 16 -22 10 -47 -26 -99 -48 -70 -95 -46 -108 56 -8 59 21 86 58 53z
m-1643 -157 c3 -14 -5 -17 -40 -17 l-43 0 30 20 c34 22 48 21 53 -3z m1262
-215 c17 -18 31 -45 35 -68 13 -83 14 -84 44 -84 41 0 94 -40 108 -81 15 -46
7 -79 -29 -123 -45 -54 -95 -76 -206 -87 -53 -5 -111 -12 -127 -15 -31 -6 -31
-5 -60 78 -62 177 -24 328 100 392 49 26 102 21 135 -12z m-2210 -640 c0 -4
-7 -18 -16 -30 -18 -25 -8 -46 27 -57 19 -6 27 -35 9 -35 -5 0 -25 8 -45 17
-46 22 -56 55 -24 89 22 23 49 32 49 16z m2554 -24 c17 -23 10 -45 -27 -85
-30 -33 -45 -28 -69 22 -21 42 -21 46 -6 62 13 13 20 14 31 5 11 -10 17 -9 27
3 17 20 26 19 44 -7z m-3095 -79 c104 -27 257 -103 386 -194 61 -42 172 -117
247 -168 76 -50 136 -93 133 -95 -5 -6 -148 81 -327 199 -237 156 -406 227
-591 251 -54 7 -97 15 -97 19 0 24 136 17 249 -12z m-321 11 c78 0 43 -16 -60
-27 -120 -14 -166 -30 -202 -73 -30 -36 -33 -66 -10 -99 l16 -23 28 27 c18 18
30 23 35 15 8 -13 -10 -37 -36 -45 -10 -4 -19 -12 -19 -18 0 -35 -24 -88 -56
-125 -19 -23 -33 -42 -30 -42 12 0 151 73 169 88 13 12 20 13 29 4 13 -13 -5
-42 -26 -42 -7 0 -54 -20 -106 -45 -51 -25 -123 -54 -160 -64 -122 -34 -140
-53 -140 -151 0 -133 52 -266 149 -378 73 -84 91 -90 205 -67 96 20 170 48
214 82 18 14 36 23 39 19 26 -26 -93 -90 -253 -135 -105 -30 -114 -31 -147
-18 -95 40 -210 216 -241 369 -40 195 -11 264 121 294 52 11 77 22 84 36 6 11
33 44 60 74 27 30 49 59 49 64 0 5 -9 33 -20 62 -11 29 -20 65 -20 79 0 30 49
102 82 120 34 19 96 28 153 23 28 -2 69 -4 93 -4z m2793 -170 c10 -6 26 -24
34 -40 14 -26 18 -29 36 -19 52 27 102 -25 77 -79 -7 -15 -36 -41 -63 -59 -28
-17 -61 -43 -75 -57 -14 -14 -29 -26 -32 -26 -3 0 -20 28 -38 62 -28 53 -32
69 -28 120 3 43 10 64 28 83 26 28 33 30 61 15z m-253 -312 c46 -50 55 -145
23 -233 -28 -76 -85 -199 -96 -207 -22 -17 -47 -7 -58 21 -7 19 -24 36 -48 46
-76 35 -135 127 -146 230 -4 41 -1 55 16 77 27 34 86 53 121 40 21 -8 30 -5
58 19 42 37 98 40 130 7z m-1702 -106 c92 -55 233 -164 285 -219 48 -52 170
-208 203 -261 15 -23 24 -47 21 -52 -14 -22 -32 -7 -58 46 -16 31 -30 55 -32
52 -2 -2 9 -55 25 -118 79 -305 91 -607 34 -826 -11 -46 -82 -276 -150 -493
-2 -8 -7 -11 -10 -8 -3 3 17 88 45 188 103 380 113 430 112 610 0 211 -46 450
-115 594 -39 83 -140 213 -197 255 -114 82 -274 79 -570 -11 -130 -39 -169
-44 -169 -19 0 30 232 113 377 135 179 27 238 19 292 -39 20 -21 61 -36 61
-22 0 16 -33 75 -52 92 -42 40 -151 104 -174 104 -25 0 -39 14 -29 30 10 16
17 13 101 -38z m2274 -97 c7 -9 21 -13 30 -10 9 3 23 -1 29 -9 15 -17 5 -40
-43 -99 -26 -33 -32 -36 -45 -23 -19 20 -27 121 -11 141 16 19 24 19 40 0z
m-2916 -211 c10 -13 16 -14 39 -3 45 20 43 1 -4 -49 -50 -52 -167 -191 -244
-291 l-50 -63 31 -28 c17 -15 46 -48 63 -74 l33 -47 17 23 c10 13 37 57 61 98
83 144 169 236 307 326 61 40 183 81 204 69 5 -4 9 -14 9 -24 0 -14 -11 -20
-47 -25 -64 -8 -170 -69 -265 -150 -77 -66 -99 -94 -207 -260 l-58 -90 19 -59
c36 -115 24 -212 -61 -510 -27 -94 -80 -287 -116 -427 -37 -140 -92 -345 -122
-455 -30 -110 -60 -218 -66 -240 -11 -44 -27 -69 -27 -43 0 9 4 27 9 40 5 13
59 240 121 504 61 263 129 542 150 619 90 324 97 449 33 567 -60 111 -132 161
-251 176 -41 5 -57 11 -57 22 0 9 11 16 28 18 31 4 37 35 12 68 -21 27 -18 51
6 57 22 6 22 6 39 -69 11 -50 13 -52 53 -63 40 -10 43 -9 62 17 11 15 75 94
143 176 67 82 120 151 117 154 -2 3 -25 -3 -50 -12 -42 -17 -65 -15 -65 4 0 4
21 16 48 26 26 9 51 21 57 26 13 11 16 10 29 -8z m-939 -58 c53 -35 42 -80
-13 -52 l-31 16 -8 -37 c-13 -69 -6 -124 25 -182 l29 -56 118 -3 117 -3 -43
51 c-100 118 -125 143 -147 151 -26 10 -25 32 1 37 26 5 74 -37 155 -136 41
-51 83 -97 93 -103 10 -5 45 -9 78 -9 49 0 61 -3 61 -16 0 -13 -26 -15 -221
-12 l-221 3 -24 30 c-49 61 -59 94 -59 197 l0 97 -25 -4 c-14 -2 -59 -6 -101
-9 -110 -8 -113 -9 -106 -69 4 -27 13 -59 20 -71 8 -11 20 -51 27 -87 13 -63
10 -99 -7 -99 -7 0 -41 105 -66 206 -14 57 -1 131 24 145 71 38 275 47 324 15z
m1637 -2 c57 -16 85 -43 31 -30 -16 4 -74 10 -130 13 -78 4 -103 9 -103 19 0
20 128 18 202 -2z m164 -108 c60 -68 104 -148 142 -259 35 -101 35 -104 33
-257 -2 -187 -11 -272 -30 -278 -11 -4 -12 3 -7 29 3 19 9 122 12 229 l6 195
-31 84 c-39 106 -101 211 -157 268 -24 24 -44 46 -44 49 0 12 42 -20 76 -60z
m345 -177 c21 -43 45 -193 60 -377 10 -116 10 -147 -1 -167 -11 -20 -10 -33 4
-77 18 -60 41 -219 51 -353 8 -124 -11 -600 -29 -710 -9 -49 -13 -100 -9 -112
6 -25 -11 -53 -33 -53 -24 0 -39 45 -25 77 30 74 32 1063 2 1125 -10 19 -11
35 -4 55 12 34 2 170 -32 427 -14 104 -25 193 -25 198 0 20 26 0 41 -33z m852
-67 c9 -10 22 -32 27 -49 l11 -30 29 28 c32 33 74 38 102 12 27 -24 24 -97 -8
-160 -33 -67 -136 -163 -174 -163 -34 0 -91 60 -111 116 -29 84 -9 240 33 257
29 12 76 6 91 -11z m-3003 -22 c41 -6 109 -13 150 -16 41 -3 93 -14 115 -24
58 -27 99 -65 92 -84 -9 -22 -34 -20 -47 4 -20 38 -88 64 -175 66 -44 2 -125
6 -180 10 -179 12 -377 -27 -461 -91 -20 -15 -49 -55 -69 -95 -49 -97 -45
-116 9 -55 71 80 63 45 -11 -53 -37 -49 -85 -123 -105 -163 -45 -91 -62 -114
-76 -105 -6 4 11 46 46 114 56 107 83 202 56 202 -6 0 -25 3 -42 6 -29 6 -32
3 -73 -67 -23 -41 -79 -128 -123 -194 -45 -66 -82 -123 -84 -127 -2 -3 18 0
44 8 90 27 213 14 324 -34 67 -29 28 -39 -66 -17 -89 21 -227 16 -287 -9 -41
-18 -84 -71 -121 -150 -36 -77 -85 -250 -90 -318 -4 -62 -18 -90 -36 -72 -6 6
-4 29 5 64 7 30 18 89 24 131 6 41 13 84 16 95 8 23 -30 -17 -131 -142 -41
-50 -74 -96 -74 -101 0 -12 44 -44 98 -72 39 -20 55 -41 32 -41 -5 0 -42 14
-80 32 -79 35 -107 68 -88 103 6 11 48 63 93 115 140 163 185 220 199 253 7
18 60 104 118 192 58 88 136 214 174 280 76 132 90 142 151 109 18 -9 34 -15
36 -13 1 2 16 33 32 67 55 118 137 164 345 196 51 7 187 5 260 -4z m3468 -161
c3 -33 -33 -89 -58 -89 -11 0 -20 1 -20 3 0 1 -7 20 -14 42 -20 56 -8 74 46
71 37 -2 43 -5 46 -27z m-2372 -123 c-9 -58 -20 -192 -26 -298 -26 -484 -39
-592 -125 -1003 -53 -252 -59 -354 -31 -540 28 -190 26 -184 109 -272 33 -34
48 -57 44 -67 -10 -25 -23 -19 -57 27 l-31 42 6 -50 c15 -106 67 -340 89 -400
35 -91 49 -114 115 -194 34 -40 61 -76 61 -81 0 -4 23 -17 50 -28 37 -16 50
-26 50 -41 0 -28 -16 -26 -52 4 -50 42 -54 37 -64 -89 -6 -74 -4 -89 13 -118
18 -30 141 -192 199 -261 13 -16 22 -33 18 -38 -12 -20 -33 -7 -58 35 -44 75
-49 70 -44 -45 3 -87 8 -112 30 -154 30 -57 80 -109 121 -124 59 -22 187 -7
263 31 52 26 156 116 149 128 -3 5 5 31 18 57 14 26 28 67 31 89 7 39 5 45
-37 92 -35 39 -48 64 -57 107 -7 31 -10 66 -7 76 3 10 3 19 0 19 -8 0 -66 -41
-95 -68 -26 -23 -48 -23 -48 1 0 7 18 22 41 35 104 60 166 145 156 216 -4 23
-56 140 -116 262 -108 216 -155 323 -148 330 2 2 30 -49 62 -113 32 -65 59
-113 61 -108 11 35 22 227 15 262 -4 23 -27 74 -51 113 -54 89 -87 171 -96
238 -5 43 -4 52 9 52 21 0 30 -16 19 -36 -12 -22 32 -141 84 -225 21 -35 45
-77 52 -93 16 -38 15 -126 -4 -243 l-15 -97 78 -156 c70 -139 78 -161 77 -210
0 -36 -8 -69 -22 -97 -42 -81 -26 -183 39 -249 40 -41 41 -44 37 -97 -6 -76
-80 -197 -167 -274 -54 -48 -74 -59 -125 -70 -139 -29 -227 -12 -296 57 -69
68 -98 150 -107 297 -6 115 -7 119 -40 158 -18 22 -45 63 -60 92 -21 44 -24
62 -19 101 22 164 23 156 -24 209 -100 114 -174 327 -220 638 -7 44 -22 99
-35 123 l-23 42 -58 -72 c-32 -40 -137 -153 -233 -250 -127 -130 -181 -178
-198 -178 -14 0 -23 6 -23 15 0 8 7 15 16 15 25 0 422 418 480 506 12 18 12
35 -1 120 -8 54 -16 114 -17 133 l-3 33 -43 -53 c-64 -79 -293 -309 -309 -309
-25 0 -13 28 20 49 32 20 253 262 303 332 18 26 26 55 33 124 5 50 21 142 35
205 70 323 114 620 141 945 19 230 59 514 72 502 2 -3 -2 -53 -11 -111z m-726
25 c0 -109 -18 -171 -199 -701 -38 -113 -79 -243 -91 -290 -25 -103 -29 -114
-35 -108 -16 16 67 319 185 677 54 162 98 314 108 373 18 106 32 128 32 49z
m638 -148 c-1 -107 -2 -112 -8 -53 -11 98 -11 170 0 170 6 0 9 -45 8 -117z
m933 -255 c-6 -46 -15 -123 -21 -173 -11 -88 -54 -341 -68 -400 -5 -21 -14
-30 -27 -30 -26 0 -25 13 13 190 16 77 33 165 36 195 l7 55 -44 -81 c-107
-197 -384 -640 -394 -631 -3 3 8 25 25 49 48 71 296 505 355 624 30 61 70 152
88 203 17 50 34 89 36 87 3 -3 0 -42 -6 -88z m-2143 19 c2 -10 -3 -17 -12 -17
-14 0 -145 -39 -155 -46 -1 -2 29 -35 69 -74 70 -71 84 -101 48 -108 -12 -2
-22 8 -31 30 -17 40 -77 102 -117 123 -37 19 -38 33 -2 41 57 12 118 35 147
54 34 22 48 21 53 -3z m-273 -149 c20 -24 43 -66 51 -96 17 -58 17 -60 -40
-307 -14 -60 -31 -150 -37 -200 -11 -87 -29 -138 -29 -83 0 50 30 254 55 377
14 68 25 137 25 154 0 54 -24 111 -62 148 -21 20 -35 39 -31 43 14 14 33 4 68
-36z m-725 -530 c0 -2 -6 -40 -14 -85 -15 -92 -15 -91 47 -116 39 -17 50 -37
20 -37 -10 0 -36 9 -58 19 -22 10 -52 21 -67 24 -25 7 -27 4 -42 -48 -26 -87
-116 -493 -116 -520 0 -14 3 -25 8 -25 16 0 231 167 282 220 30 31 79 97 108
146 60 100 60 104 -25 127 -24 6 -43 15 -43 19 0 13 22 9 69 -12 59 -27 66
-26 117 19 24 21 77 53 119 71 41 18 75 37 75 41 0 5 -16 9 -36 9 -24 0 -34 4
-31 13 2 7 23 12 55 12 27 0 54 3 58 8 11 10 52 9 59 -2 3 -6 -3 -14 -12 -20
-14 -8 -15 -11 -3 -11 8 0 28 -11 44 -25 25 -20 28 -26 17 -39 -15 -18 -57
-21 -94 -7 -23 9 -27 7 -41 -30 -9 -22 -23 -39 -31 -39 -13 0 -14 4 -5 21 7
11 9 23 6 27 -4 3 -12 -9 -18 -26 -14 -35 -25 -41 -35 -16 -4 10 4 24 21 39
40 34 31 37 -31 9 -62 -27 -133 -72 -133 -84 0 -11 42 -26 145 -55 118 -33
125 -32 125 5 0 20 5 30 15 30 22 0 19 -55 -9 -146 -35 -115 -145 -421 -145
-406 -1 13 110 409 124 443 7 18 7 18 -173 78 -51 18 -97 29 -101 27 -5 -3
-33 -47 -64 -98 -30 -51 -74 -115 -97 -141 -47 -53 -171 -152 -270 -216 l-64
-42 -18 -87 c-10 -48 -18 -90 -18 -94 0 -4 30 -23 68 -42 76 -40 71 -46 -13
-14 -48 18 -58 19 -66 7 -9 -14 -14 -79 -11 -137 2 -47 -20 -36 -31 14 -10 46
5 169 32 267 10 37 17 67 16 67 -1 0 -28 -9 -61 -20 -86 -30 -154 -47 -366
-90 -213 -43 -295 -76 -391 -154 -54 -44 -77 -51 -77 -22 0 14 157 117 216
142 49 21 113 39 187 54 44 9 57 16 57 29 0 11 16 25 42 37 57 26 184 121 231
173 22 24 67 102 102 175 70 144 72 147 131 193 33 26 43 42 54 87 18 76 44
116 74 116 21 0 27 9 45 70 12 39 26 70 31 70 6 0 10 -1 10 -2z m3006 -110
c-11 -132 -44 -350 -79 -523 -29 -141 -35 -160 -52 -160 -24 0 -26 -12 23 227
23 109 50 251 62 315 31 178 41 223 47 223 3 0 2 -37 -1 -82z m-2339 -135 c-3
-10 -8 -36 -12 -58 -10 -65 -67 -269 -78 -280 -6 -7 -7 -3 -3 10 9 33 54 226
66 288 6 31 16 57 21 57 6 0 8 -8 6 -17z m242 -171 c-18 -83 28 -125 181 -161
122 -30 209 -41 336 -41 175 0 278 -26 289 -73 3 -12 -1 -38 -9 -57 -9 -23
-11 -40 -5 -52 7 -12 5 -25 -5 -40 -7 -13 -17 -45 -20 -73 -3 -27 -26 -122
-50 -210 -32 -120 -41 -165 -33 -180 7 -13 6 -20 -2 -23 -6 -2 -11 -12 -11
-22 0 -19 -23 -43 -33 -34 -10 10 5 98 20 112 7 7 13 22 13 33 0 11 16 102 36
202 20 100 36 195 36 211 -1 16 6 42 14 58 8 16 14 41 12 56 -4 36 -49 51
-203 63 -66 6 -181 19 -255 29 -286 39 -363 92 -319 219 15 44 20 34 8 -17z
m2484 -57 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12 3 -20z m-2626
-158 c25 -26 99 -62 168 -83 33 -9 54 -19 47 -22 -7 -2 -51 8 -97 22 -91 28
-165 84 -165 122 1 18 1 18 15 -1 8 -11 22 -28 32 -38z m726 -133 c48 -10 87
-21 87 -26 0 -9 -29 -7 -210 12 -47 5 -129 12 -182 16 -55 4 -98 11 -98 17 0
15 301 1 403 -19z m-992 -135 c-22 -79 -56 -214 -76 -301 -53 -230 -83 -275
-187 -288 -50 -7 -169 2 -213 14 -60 18 98 -47 195 -80 141 -48 114 -59 -46
-19 -130 32 -184 55 -249 104 -27 21 -78 53 -113 71 -82 43 -130 99 -106 123
4 3 16 -8 28 -26 47 -70 283 -157 436 -160 98 -2 141 15 174 72 10 18 46 133
78 255 67 251 108 389 114 384 2 -3 -14 -69 -35 -149z m689 12 c13 -94 9 -126
-17 -133 -31 -8 -43 3 -43 38 0 30 40 187 46 181 1 -1 8 -40 14 -86z m-856
-211 c-60 -102 -92 -130 -147 -130 -61 1 -241 62 -245 83 -4 22 30 23 48 2 18
-21 134 -55 190 -55 59 0 85 24 142 128 27 49 44 70 51 63 6 -6 -7 -37 -39
-91z m1223 -370 c-7 -81 -25 -106 -90 -125 -70 -20 -178 -35 -259 -35 -42 0
-68 -4 -70 -12 -3 -9 -38 -8 -144 6 -274 34 -517 106 -481 142 3 3 18 -4 34
-15 46 -34 135 -49 378 -67 325 -23 519 -9 575 41 10 9 24 41 30 71 16 75 34
71 27 -6z m-1105 -145 c89 -25 315 -47 454 -43 143 3 160 -6 47 -28 -139 -26
-202 -27 -324 -5 -64 11 -145 30 -180 41 -35 11 -72 20 -81 20 -18 0 -24 20
-11 34 4 4 17 4 28 1 11 -4 41 -13 67 -20z"/>
                    <path d="M8000 9647 c-14 -7 -34 -28 -47 -47 -52 -82 -45 -78 -97 -52 -51 24
-105 29 -124 10 -33 -33 -3 -115 67 -187 40 -41 158 -120 164 -110 2 2 12 27
24 54 11 28 37 86 57 130 41 91 48 162 19 187 -30 25 -38 27 -63 15z"/>
                    <path d="M7491 8885 c-36 -31 -64 -110 -65 -185 -1 -74 -19 -75 -80 -4 -56 65
-99 94 -139 94 -57 0 -60 -89 -5 -192 40 -76 112 -142 230 -211 53 -31 100
-61 103 -67 3 -5 10 -10 14 -10 15 0 82 158 97 231 33 156 2 319 -67 354 -39
21 -53 19 -88 -10z"/>
                    <path d="M8295 8590 c-8 -16 -15 -38 -15 -48 0 -21 -25 -36 -41 -26 -5 3 -9
12 -9 20 0 23 -47 64 -73 64 -51 0 -78 -69 -58 -143 16 -56 67 -124 123 -164
l44 -32 51 77 c29 42 57 90 62 105 17 44 13 109 -9 145 -25 41 -54 42 -75 2z"/>
                    <path d="M7382 7793 l-21 -28 -21 24 c-23 27 -50 22 -50 -9 0 -27 22 -70 48
-93 19 -17 20 -16 55 26 32 40 41 62 38 95 -2 21 -26 13 -49 -15z"/>
                    <path d="M7894 6885 c-18 -28 -45 -32 -68 -10 -22 22 -36 18 -36 -10 0 -37 25
-80 60 -105 17 -12 32 -21 33 -19 47 88 57 112 57 138 0 38 -23 41 -46 6z"/>
                    <path d="M7885 6184 c-45 -23 -72 -52 -96 -102 -19 -37 -21 -55 -16 -120 3
-43 12 -95 20 -117 l14 -40 84 -3 c105 -4 158 11 209 57 56 51 63 90 21 132
-28 28 -37 31 -72 26 -32 -4 -41 -2 -50 15 -6 11 -8 26 -5 35 10 24 -15 103
-37 118 -27 19 -35 19 -72 -1z"/>
                    <path d="M7670 5251 c-15 -29 -12 -108 5 -142 l16 -30 61 43 c93 65 106 102
28 82 -38 -11 -38 -11 -56 28 -19 42 -38 48 -54 19z"/>
                    <path d="M7361 4931 c-10 -10 -23 -27 -29 -37 -9 -17 -14 -17 -44 -6 -81 29
-108 3 -82 -75 24 -73 51 -111 98 -138 24 -14 47 -25 52 -25 5 0 22 31 38 68
38 86 48 198 20 219 -24 17 -30 17 -53 -6z"/>
                    <path d="M3510 2640 c0 -12 40 -33 48 -25 4 4 -2 13 -12 21 -23 16 -36 18 -36
4z"/>
                    <path d="M2742 2435 c-16 -13 -46 -60 -67 -102 -20 -43 -51 -107 -68 -143 -39
-81 -115 -158 -232 -234 -47 -32 -85 -60 -82 -62 15 -15 368 83 393 110 17 17
106 456 93 456 -4 0 -21 -11 -37 -25z"/>
                    <path d="M4120 1882 c-9 -41 -10 -56 -1 -65 25 -25 41 49 22 98 -6 16 -11 9
-21 -33z"/>
                  </g>
                </svg>


              </div>

            </div>

            <div class="_inner_kdytn_1">
              <div @click="prevScreen" class="_left_kdytn_125"></div>
              <div @click="nextScreen" class="_right_kdytn_135"></div>

              <div v-if="userData.is_premium === true" class="_progressBar_kdytn_97">
                <div :style="{opacity: currentScreen >= 1 ? '1' : ''}" class="_bar_kdytn_109"></div>
                <div :style="{opacity: currentScreen >= 2 ? '1' : ''}" class="_bar_kdytn_109"></div>
                <div :style="{opacity: currentScreen >= 3 ? '1' : ''}" class="_bar_kdytn_109"></div>
              </div>

              <div v-else class="_progressBar_kdytn_97">
                <div :style="{opacity: currentScreen >= 1 ? '1' : ''}" class="_bar_kdytn_109"></div>
                <div :style="{opacity: currentScreen >= 2 ? '1' : ''}" class="_bar_kdytn_109"></div>
              </div>

              <template v-if="userData.is_premium === true">
                <template v-if="currentScreen === 1">
                  <div class="_title_kdytn_28">Elite member!</div>
                  <div class="_subTitle_kdytn_38">You've joined Telegram</div>

                  <div class="_valueWrap_kdytn_46">
                    <div class="_value_kdytn_46">{{ userData.years_using }}</div>
                    <div class="_valueTitle_kdytn_82">years ago</div>
                  </div>
                </template>

                <template v-if="currentScreen === 2">
                  <div class="_title_kdytn_28">Telegram Premium</div>
                  <div class="_subTitle_kdytn_38">You know how to get the best</div>

                  <div class="_valueWrap_kdytn_46">
                    <div class="_value_kdytn_46">
                      <img src="/star.png">
                    </div>
                    <div class="_valueTitle_kdytn_82">Premium user</div>
                  </div>
                  <div class="_valueSubTitle_kdytn_90">Status confirmed</div>
                </template>

                <template v-if="currentScreen === 3">
                  <div class="pyro">
                    <div class="before"></div>
                    <div class="after"></div>
                  </div>
                  <div class="_title_kdytn_28">You are amazing!</div>
                  <div class="_subTitle_kdytn_38">Here is your LOVE reward</div>

                  <div class="_valueWrap_kdytn_46">
                    <div class="_value_kdytn_46">
                      <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 20V5.99995M12 5.99995C10.2006 3.90293 7.19377 3.25485 4.93923 5.17509C2.68468 7.09533 2.36727 10.3059 4.13778 12.577C5.60984 14.4652 10.0648 18.4477 11.5249 19.7366C11.6882 19.8808 11.7699 19.9529 11.8652 19.9813C11.9483 20.006 12.0393 20.006 12.1225 19.9813C12.2178 19.9529 12.2994 19.8808 12.4628 19.7366C13.9229 18.4477 18.3778 14.4652 19.8499 12.577C21.6204 10.3059 21.3417 7.07513 19.0484 5.17509C16.7551 3.27505 13.7994 3.90293 12 5.99995Z"
                            stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>

                    <div class="_valueTitle_kdytn_82">4,062</div>
                  </div>
                  <div class="_valueSubTitle_kdytn_90">Thank You For Joining Us!</div>
                </template>
              </template>
              <template v-else>
                <template v-if="currentScreen === 1">
                  <div class="_title_kdytn_28">Elite member!</div>
                  <div class="_subTitle_kdytn_38">You've joined Telegram</div>

                  <div class="_valueWrap_kdytn_46">
                    <div class="_value_kdytn_46">{{ userData.years_using }}</div>
                    <div class="_valueTitle_kdytn_82">years ago</div>
                  </div>
                </template>

                <template v-if="currentScreen === 2">
                  <div class="pyro">
                    <div class="before"></div>
                    <div class="after"></div>
                  </div>
                  <div class="_title_kdytn_28">You are amazing!</div>
                  <div class="_subTitle_kdytn_38">Here is your LOVE reward</div>

                  <div class="_valueWrap_kdytn_46">
                    <div class="_value_kdytn_46">
                      <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 20V5.99995M12 5.99995C10.2006 3.90293 7.19377 3.25485 4.93923 5.17509C2.68468 7.09533 2.36727 10.3059 4.13778 12.577C5.60984 14.4652 10.0648 18.4477 11.5249 19.7366C11.6882 19.8808 11.7699 19.9529 11.8652 19.9813C11.9483 20.006 12.0393 20.006 12.1225 19.9813C12.2178 19.9529 12.2994 19.8808 12.4628 19.7366C13.9229 18.4477 18.3778 14.4652 19.8499 12.577C21.6204 10.3059 21.3417 7.07513 19.0484 5.17509C16.7551 3.27505 13.7994 3.90293 12 5.99995Z"
                            stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>

                    <div class="_valueTitle_kdytn_82">4,062</div>
                  </div>
                  <div class="_valueSubTitle_kdytn_90">Thank You For Joining Us!</div>
                </template>
              </template>

              <div @click="nextScreen"
                   class="_root_er6dp_1 _type-white_er6dp_28 _fixedBottom_er6dp_72 _button_kdytn_145">Continue
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

  </div>


</template>

<script>
import axios from "axios";
import {mapActions, mapState} from "vuex";

export default {
  name: 'AppView',

  data() {
    return {
      userExists: null,
      userData: null,
      showContent: 'welcome_window',
      isLastProgress: false,
      isShowRewardSlide: false,
      loaders: [
        {progress: 0, visible: true, text: 'Account Age Verified', last: false},
        {progress: 0, visible: false, text: 'Telegram Premium Checked', last: false},
        {progress: 0, visible: false, text: 'Activity Level Analyzed', last: false},
        {progress: 0, visible: false, text: 'OG Status Confirmed', last: true},
      ],
      currentScreen: 1,
      countOfPressesNextBTN: 0,
      userVisitWebMiniApp: false
    };


  },

  computed: {
    ...mapState('user', ['userDataTg']),
  },


  async mounted() {
    this.test()


    try {
      const response = await axios.get('https://adminhr.site/api/telegram/user-exist', {
        params: {
          userData: window.Telegram.WebApp.initDataUnsafe,
        },
      });

      console.log('User exist ?', response)


      if (response.data.exists) {
        this.userExists = true;
        // Загрузите данные пользователя, если необходимо
        const userDataResponse = await axios.get('https://adminhr.site/api/telegram/user-data', {
          params: {
            userId: window.Telegram.WebApp.initDataUnsafe.user.id,
          },
        });
        // Обработайте данные пользователя
        console.log(userDataResponse.data);
      } else {
        this.userExists = false;

        const userDataResponse = await axios.get('https://adminhr.site/api/telegram/user-data', {
          params: {
            userId: window.Telegram.WebApp.initDataUnsafe.user.id,
          },
        });

        // Обработайте данные пользователя
        console.log('uuuu', userDataResponse.data);
      }
    } catch (error) {
      console.error('Произошла ошибка при загрузке данных:', error);
    }
  },

  methods: {
    ...mapActions('user', ['fetchUserByTgId']),

    async test() {
      await this.fetchUserByTgId(window.Telegram.WebApp.initDataUnsafe.user.id)
    },

    showAwardCalculation() {
      this.showContent = "award_calculation"
      this.startProgress(0);
      this.awardVerification()
    },

    async awardVerification() {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id;

      try {
        const response = await axios.get(`https://adminhr.site/api/telegram/award-verification/${userId}`);
        // // Обработайте ответ, если это необходимо
        // console.log(response.data);
        //
        this.userData = response.data

        console.log('111111',this.userData)
      } catch (error) {
        console.error('Произошла ошибка при загрузке данных:', error);
      }
    },

    switchIsLastProgress() {
      this.isLastProgress = true
    },

    startProgress(index) {
      if (index >= this.loaders.length) return;


      const interval = setInterval(() => {
        if (this.loaders[index].progress < 100) {
          this.loaders[index].progress += 10;
        } else {
          clearInterval(interval);
          if (index + 1 < this.loaders.length) {
            this.loaders[index + 1].visible = true;
            this.startProgress(index + 1);
          }
        }
        if (this.loaders[index].last === true) {
          this.isLastProgress = true
        }
      }, 100); // Adjust the speed of progress here

    },

    prevScreen() {
      if (this.currentScreen > 1) {
        this.currentScreen--;
      }
    },
    async nextScreen() {
      if (this.userData.is_premium) {
        if (this.currentScreen < 3) {
          this.currentScreen++;
        } else {
          this.userExists = true;

          try {
            const userId = window.Telegram.WebApp.initDataUnsafe.user.id;

            const response = await axios.get(`https://adminhr.site/api/telegram/user-visit/${userId}`);
            // // Обработайте ответ, если это необходимо
            // console.log(response.data);
            //
            this.userVisitWebMiniApp = response.data

            console.log(this.userVisitWebMiniApp)
          } catch (error) {
            console.error('Произошла ошибка при загрузке данных:', error);
          }
        }
      } else {
        if (this.currentScreen < 2) {
          this.currentScreen++;
        } else {
          this.userExists = true;

          try {
            const userId = window.Telegram.WebApp.initDataUnsafe.user.id;

            const response = await axios.get(`https://adminhr.site/api/telegram/user-visit/${userId}`);
            // // Обработайте ответ, если это необходимо
            // console.log(response.data);
            //
            this.userVisitWebMiniApp = response.data

            console.log(this.userVisitWebMiniApp)
          } catch (error) {
            console.error('Произошла ошибка при загрузке данных:', error);
          }
        }
      }


    },


  },
}
</script>


<style>

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-webkit-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-moz-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-o-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-ms-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}


._valueSubTitle_kdytn_90 {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 510;
}

._value_kdytn_46 img {
  width: 206px;
  height: 161px;
  margin-bottom: 30px;
}

._value_kdytn_46 svg {
  width: 165px;
  height: 169px;
}

._barCompleted_kdytn_121 {
  --opacity: 1;
}

._left_kdytn_125 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 120;
}

._right_kdytn_135 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 120;
}


._view_kdytn_11:before {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
  background-image: url(https://cdn.onetime.dog/public/stars.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 100;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 2s ease-in-out;
}

.fade-enter, .fade-leave-to /* .fade-leave-active в версии <2.1.8 */
{
  opacity: 0;
}

._animation_kdytn_73 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 90;
  width: 100%;
}

._root_er6dp_1 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  padding: 0 16px;
  text-align: center;
  border-radius: 10px;
  font-size: 17px;
  min-height: 50px;
  height: 50px;
  font-weight: 590;
  border: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
  user-select: none;
  transition: background-color ease;
  transition-duration: .3s;
  background-color: #fff;
  color: #000;
  position: fixed;
  bottom: 24px;
  left: 24px;
  right: 24px;
  backdrop-filter: blur(12px);
  z-index: 130;
}

._value_kdytn_46 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums slashed-zero;
  font-size: 234px;
  font-weight: 860;
  line-height: 234px;
  text-align: center;
  position: relative;
}

._valueTitle_kdytn_82 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 20px;
}

._valueWrap_kdytn_46 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

._subTitle_kdytn_38 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 510;
  margin-top: 20px;
}

._title_kdytn_28 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums slashed-zero;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 12px;
}

._bar_kdytn_109 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: #fff;
  -webkit-box-flex: 1;
  flex-grow: 1;
  opacity: .5;
  margin-right: 9px;
  transition: background-color .1s ease;
}

._progressBar_kdytn_97 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  height: 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: absolute;
  left: 38px;
  top: 0;
  right: 38px;
  z-index: 110;
}

._inner_kdytn_1 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  position: relative;
  z-index: 110;
  height: 100vh;
  padding-bottom: 98px;
  padding-top: 24px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

._view_kdytn_11 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  margin-left: -16px;
  margin-right: -16px;
  opacity: 1;
}

/* CSS для анимации */
.loader-container {
  transition: transform 0.5s ease;
  margin-bottom: 10px; /* Отступ между элементами */
}

.loader-enter-active, .loader-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease; /* Анимация появления и исчезновения */
}

.loader-enter, .loader-leave-to {
  opacity: 0;
  transform: translateY(20px); /* Смещение вниз для исчезновения */
}

._checkmark_jzemx_23 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  width: 24;
  height: 24;
  fill: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  margin-left: auto;
}

._loaderProgress_jzemx_51 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  background-color: #007aff;
  height: 8px;
  transition-property: width;
  width: 100%;
  transition-duration: 2100ms;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}

._loader_jzemx_15 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  height: 8px;
  background-color: #666;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
}

._name_jzemx_33 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
}

._top_jzemx_39 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  margin-bottom: 4px;
  display: flex;
}

._loaders_jzemx_15 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 34px;
}

._view_jzemx_1 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 98px;
  padding-top: 24px;
  opacity: 1;
}

._title_jzemx_9 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
}

._root_a3nd8_1 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  padding: 0 16px;
  text-align: center;
  border-radius: 10px;
  font-size: 17px;
  min-height: 50px;
  height: 50px;
  font-weight: 590;
  border: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 2px;
  user-select: none;
  transition: background-color ease;
  transition-duration: .3s;
  cursor: pointer;
  background-color: #007aff;
  color: #fff;
  position: fixed;
  bottom: 24px;
  left: 24px;
  right: 24px;
  backdrop-filter: blur(12px);
}

._image_15p71_9 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

._text_15p71_16 {
  --color-black: var(--palette-black);
  --color-white: var(--palette-white);
  --color-tg-button: var(--tg-theme-button-color);
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-ios-rounded: "SF Pro Rounded", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --k-device-pixel-ratio: 2;
  --body-padding: 24px;
  --safe-area-left: 24px;
  --safe-area-right: 24px;
  --palette-dark-4: #efeff3;
  --palette-dark-20: #8e8e93;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-65: rgba(84, 84, 88, .65);
  --palette-transparent-grey-18: rgba(116, 116, 128, .18);
  --palette-transparent-grey-8: rgba(116, 116, 128, .08);
  --palette-transparent-white-05: rgba(255, 255, 255, .05);
  --palette-transparent-white-75: rgba(255, 255, 255, .75);
  --palette-transparent-white-60: rgba(255, 255, 255, .6);
  --palette-transparent-white-50: rgba(255, 255, 255, .5);
  --palette-transparent-white-30: rgba(255, 255, 255, .3);
  --palette-transparent-white-12: rgba(255, 255, 255, .12);
  --palette-transparent-white-18: rgba(255, 255, 255, .18);
  --palette-transparent-dark-05: rgba(0, 0, 0, .05);
  --palette-transparent-dark-80: rgba(45, 45, 45, .8);
  --palette-transparent-dark-75: rgba(60, 60, 67, .75);
  --palette-transparent-dark-60: rgba(60, 60, 67, .6);
  --palette-transparent-dark-50: rgba(60, 60, 67, .5);
  --palette-transparent-dark-36: rgba(60, 60, 67, .36);
  --palette-transparent-dark-30: rgba(60, 60, 67, .3);
  --palette-transparent-dark-12: rgba(60, 60, 67, .12);
  --palette-transparent-dark-18: rgba(60, 60, 67, .18);
  --palette-brand-dark: #42a0ff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green: #2ec06d;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, .1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, .7);
  --tg-color-scheme: light;
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #707579;
  --tg-theme-link-color: #3390ec;
  --tg-theme-button-color: #3390ec;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #f4f4f5;
  --tg-theme-header-bg-color: #ffffff;
  --tg-theme-accent-text-color: #3390ec;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #707579;
  --tg-theme-subtitle-text-color: #707579;
  --tg-theme-destructive-text-color: #e53935;
  --tg-viewport-height: 574.5596313476562px;
  --tg-viewport-stable-height: 574.5596313476562px;
  text-rendering: optimizeSpeed;
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  --color-bg-primary: var(--palette-dark);
  --color-bg-secondary: var(--palette-dark-90);
  --color-bg-button-tertiary: var(--palette-transparent-grey-18);
  --color-bg-tertiary: var(--palette-transparent-white-05);
  --color-bg-skeleton: var(--palette-transparent-grey-18);
  --color-bg-section: #111112;
  --color-bg-section-light: #1c1c1e;
  --color-text-primary: var(--palette-white);
  --color-text-middle: var(--palette-transparent-white-75);
  --color-text-secondary: var(--palette-transparent-white-50);
  --color-text-tertiary: var(--palette-transparent-white-30);
  --color-text-fourth: var(--palette-transparent-white-18);
  --color-text-hint: var(--palette-dark-20);
  --color-text-accent: #42a0ff;
  --color-text-invalid: #ff5a50;
  --color-text-success: var(--palette-green);
  --color-separator: var(--palette-transparent-grey-65);
  --color-accent-brand: var(--palette-brand-dark);
  --color-accent-red: var(--palette-red-dark);
  --color-accent-green: var(--palette-green-light);
  --color-link: var(--palette-brand-light);
  --elevation-1: var(--elevation-dark-1);
  -webkit-user-select: none;
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0.3) transparent;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
  color: #a2acb0;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 36px;
}

.unstyled-link {
  text-decoration: none; /* Убираем подчеркивание */
  color: inherit; /* Используем цвет текста родителя */
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  position: relative;
}

.heart {
  width: 70px;
  height: 70px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  opacity: 0;
  animation: fadeInOut 3s infinite;
}

.heart::before,
.heart::after {
  content: '';
  display: block;
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
}

.heart::before {
  top: -35px;
  left: 0;
}

.heart::after {
  top: 0;
  left: -35px;
}

.heart:nth-child(2) {
  animation-delay: 1s;
  left: 20%;
}

.heart:nth-child(3) {
  animation-delay: 2s;
  right: 20%;
}

.heart:nth-child(4) {
  animation-delay: 3s;
  top: 20%;
}

.heart:nth-child(5) {
  animation-delay: 4s;
  bottom: 20%;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
    transform: scale(0.5) rotate(45deg);
  }
  50% {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}
</style>